<template>
  <v-dialog
    v-model="openDialog"
    content-class="elevation-0"
    cols="4"
    :width="defaultDialogSize[$vuetify.breakpoint.name]"
    persistent
    @keydown.enter.prevent="send"
    @keydown.esc.prevent="closeDialog"
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>
          {{ $t('base.bug_report') }}
        </v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-form
        :disabled="sending"
        @submit.prevent="send"
      >
        <v-card-text>
          <v-textarea
            v-model="formData.text"
            :label="$t('base/bug_report.text_label')"
            :error-messages="validationErrors.text"
            filled
            auto-grow
            @input="clearInputValidationErrors"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            :disabled="sending"
            @click="closeDialog"
          >
            {{ $t('base.cancel') }}
          </v-btn>

          <v-btn
            type="submit"
            color="primary"
            text
            :disabled="sending"
          >
            {{ $t('base.send') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { dataUriToBlob } from '@/global/services/helpers/files'
import html2canvas from 'html2canvas'
import { api } from '@/global/services/api'
import { has } from 'lodash'

export default {
  props: {
    menu: {
      type: Object,
      default: () => ({})
    },

    openModal: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      openDialog: false,
      defaultDialogSize: { xl: '33%', lg: '33%', md: '50%', sm: '83.33%', xs: '100%' },
      sending: false,
      formData: {
        text: null,
        screenshot: null
      },
      validationErrors: {}
    }
  },

  watch: {
    openModal: function (newVal) {
      this.openDialog = newVal
    }
  },

  methods: {
    async send () {
      if (this.sending) return

      this.sending = true
      this.validationErrors = {}

      try {
        await api().base.post('bug-report/validate', this.getFormData())
        this.closeDialog(false)
        setTimeout(async () => {
          await this.takeScreenshot()
          await api().base.post((this.menu.route || ''), this.getFormData())
          this.resetFormData()
        }, 50)
      }
      catch (e) {
        e.isValidationError
          ? this.validationErrors = e.getValidationErrors()
          : this.closeDialog()
      }
      finally {
        this.sending = false
      }
    },

    closeDialog (resetFormData = true) {
      if (resetFormData) {
        this.resetFormData()
      }
      this.validationErrors = {}
      this.$emit('close-dialog')
    },

    resetFormData () {
      this.formData = Object.assign({}, { text: null })
    },

    clearInputValidationErrors () {
      if (has(this.validationErrors, 'text')) {
        this.validationErrors.text = []
      }
    },

    async takeScreenshot () {
      const screenshotTarget = document.querySelector('#mainWrapper')
      const canvas = await html2canvas(screenshotTarget)
      this.formData.screenshot = canvas.toDataURL('image/png')
    },

    getFormData () {
      const formData = new FormData()

      formData.append('text', this.formData.text)
      formData.append('page_name', this.$route.name)
      formData.append('page_path', this.$route.path)

      if (this.formData.screenshot) {
        const screenshot = dataUriToBlob(this.formData.screenshot)
        formData.append('screenshot', screenshot, 'screenshot.png')
      }

      return formData
    }
  }
}
</script>
